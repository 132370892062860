import React, { useEffect, useState } from "react";
import Slider from "../Slider";
import { Header } from "../Header";
import {
  isAppRunningAsPwa,
  addCheckInTimeInFormattedDate,
  addCheckOutTimeInFormattedDate,
  isChromeOnIos,
} from "../../utils";

const IOS = (props) => {
  const [showA2hsBanner, setShowA2hsBanner] = useState(true); // to keep track of whether to show a2hs banner or not
  const appRuningAsPwa = isAppRunningAsPwa();
  const {
    reservation: {
      checkin_date,
      checkout_date,
      property_name,
      room_no,
      room_qr_image,
    },
    qrCode,
    showOverlay,
  } = props;
  const checkInDateTime = addCheckInTimeInFormattedDate(checkin_date);
  const checkOutDateTime = addCheckOutTimeInFormattedDate(checkout_date);

  /**
   * if browser opened is
   * Chrome, then do not show any a2hs banner
   * otherwise show.
   */
  useEffect(() => {
    if (isChromeOnIos()) {
      setShowA2hsBanner(false);
    } else {
      setShowA2hsBanner(true);
    }
  }, []);

  return (
    <div className={`andriod-page-wrapper ${showOverlay && "blurredBg"} `}>
      <Header />
      <div className="qr-code-wrap">
        <img src={qrCode} alt="" />
      </div>
      <div className="hotel-details-wrap">
        <div className="hotel-detail">
          <label className="title">Hotel</label>
          <span className="about-hotel">Hollywood Hills Hotel</span>
        </div>
        <div className="room-details">
          <label className="title">room number</label>
          {process.env.REACT_APP_ENV === "dev"
            ? "117 - Accessible"
            : room_no}{" "}
        </div>
        <div className="qr-validity">
          <label className="title">QR Code Validity</label>
          <span className="about-hotel">
            {checkInDateTime} - {checkOutDateTime}
          </span>
        </div>
      </div>

      <div className="alert-div">
        <span className="alert-text">
          Do not screenshot, it will not work as the code changes.
        </span>
      </div>

      {!appRuningAsPwa && showA2hsBanner && (
        <div className="slider-wrapper-custom">
          <Slider />
        </div>
      )}
    </div>
  );
};

export default IOS;
