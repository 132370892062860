import { useEffect, useState } from "react";
import axios from "axios";
import queryString from "query-string";
import Android from "./app/pages/android";
import IOS from "./app/pages/ios";
import ExpireState from "./app/ExpireState";
import { Spinner } from "./app/Spinner";
import "./App.css";
import ScreenIcon from "./logo-1.png";
import { isAppRunningAsPwa } from "./utils";
import { useQuery } from "react-query";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import { useGeolocated } from "react-geolocated";

const getDirection = (degrees, isLongitude) =>
  degrees > 0 ? (isLongitude ? "E" : "N") : isLongitude ? "W" : "S";

// adapted from http://stackoverflow.com/a/5786281/2546338
const formatDegrees = (degrees, isLongitude) =>
  `${0 | degrees}° ${
    0 | (((degrees < 0 ? (degrees = -degrees) : degrees) % 1) * 60)
  }' ${0 | (((degrees * 60) % 1) * 60)}" ${getDirection(degrees, isLongitude)}`;

const KEY_EXPIRATION = "Key-expired";

function App() {
  const [loading, setLoading] = useState(true);
  const [imageStr, setImageStr] = useState(null);
  const [reservation, setReservation] = useState(null);
  const [isError, setError] = useState(false);
  const [open, setOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  const {
    coords,
    getPosition,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 10000,
  });

  const closeModal = () => setOpen(false);
  const toggleChecked = () => setIsChecked(!isChecked);

  /** below 2 states manages a2hs flow */
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  const queryData = queryString.parse(window.location.search);

  // Calling the getQRImage api every 5 mins beacuse each QR code is only valid for 5 mins.
  const { data, status } = useQuery("getQRImage", getKeyImageService, {
    refetchInterval: 300000, //time in secs = 5mins
  });

  useEffect(() => {
    // In case of QR code expired/invalid, api will throw an error
    if (status === "error") {
      setLoading(false);
      setError(true);
    }
    if (data) {
      // if last status was "key-expired" and key gets activated again by resending key, then resetting error state.
      isError && setError(false);
      setLoading(false);
      setReservation(data);
      // converting the base 64 into image
      data &&
        data.room_qr_image &&
        setImageStr("data:image/png;base64, " + data.room_qr_image);
    }
  }, [status, data]);

  /**
   * Adding event listener for beforeinstallprompt
   * that tells us whether to show a2hs or not.
   */
  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => {
      window.removeEventListener("transitionend", handler);
    };
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      getGeoLocation();
    }, 5000);

    return () => clearInterval(id);
  }, []);

  /**
   * Get Geo location
   */
  const getGeoLocation = async () => {
    try {
      getPosition();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (coords) {
      console.log(coords);
      saveGeoLocation(coords.latitude, coords.longitude);
    }

    return () => {};
  }, [coords]);

  const saveGeoLocation = async (latitude, longitude) => {
    debugger;
    try {
      let reservationId = queryData.reservationid || queryData.reservationId;
      if (reservationId) {
        localStorage.setItem("reservationId", reservationId);
      } else {
        reservationId = localStorage.getItem("reservationId");
      }
      console.log(reservationId);

      if (
        latitude &&
        longitude &&
        isGeolocationAvailable &&
        isGeolocationEnabled
      ) {
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}reservations/updateGeoLocation`,
          { reservationId, latitude, longitude }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Service method: to call an api getQRImage
   */
  async function getKeyImageService() {
    let reservationId = queryData.reservationid || queryData.reservationId;
    /** because this PWA is also used as standalone app
     * we have to store the last reservationId in a local storage.
     * Then, if we have reservationId in query parameter,
     * then good, fetch data using that id,
     * otherwise
     * get reservationId from the local storage
     * and fetch data.
     */
    if (reservationId) {
      localStorage.setItem("reservationId", reservationId);
    } else {
      reservationId = localStorage.getItem("reservationId");
    }
    try {
      const image = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}reservationKey/getRoomKeyImage?reservationId=${reservationId}`,
        headers: {
          Accept: "application/ecmascript",
        },
      });

      return image.data;
    } catch (error) {
      console.log("Error while getting QR image:", error);
      throw error;
    }
  }

  /** when a2hs is clicked */
  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
    // Wait for the user to respond to the prompt
    promptInstall.userChoice.then((choiceResult) => {
      // if user accepts the installation, remove a2hs banner
      if (choiceResult.outcome === "accepted") {
        setSupportsPWA(false);
      }
    });
  };

  /**
   * conditions -
   * to check whether a2hs should be shown or not.
   */
  const checkWhetherToShowA2hs = () => {
    return (
      (!isAppRunningAsPwa() &&
        supportsPWA &&
        queryData.page === "Android" &&
        reservation &&
        !whetherToShowOverlay()) ||
      (!isAppRunningAsPwa() &&
        supportsPWA &&
        reservation &&
        !whetherToShowOverlay() &&
        !(
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/iPod/i)
        ))
    );
  };

  /** whether to show expiration overlay or not */
  const whetherToShowOverlay = () => {
    // Now, on we will be showing overlay if api throws an error because of changed flow at backend side.
    // Not removing this method as of now, in case we need to add multiple conditions, we can simply add there.
    return isError;
  };

  const showA2hs = checkWhetherToShowA2hs();
  const showOverlay = whetherToShowOverlay();
  let page;
  if (queryData.page === "Android" && reservation) {
    page = (
      <Android
        reservation={reservation}
        qrCode={imageStr}
        showOverlay={showOverlay}
      />
    );
  } else if (reservation) {
    page = (
      <IOS
        reservation={reservation}
        qrCode={imageStr}
        showOverlay={showOverlay}
      />
    );
  }

  if (
    reservation &&
    (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i))
  ) {
    page = (
      <IOS
        reservation={reservation}
        qrCode={imageStr}
        showOverlay={showOverlay}
      />
    );
  } else if (reservation) {
    page = (
      <Android
        reservation={reservation}
        qrCode={imageStr}
        showOverlay={showOverlay}
      />
    );
  }

  return (
    <div className="App">
      <Popup
        modal={true}
        lockScroll={true}
        nested={true}
        open={open}
        closeOnDocumentClick={false}
        onClose={closeModal}
      >
        <div className="content">
          <h2 className="header"> TO USE YOUR KEY</h2>
          <br />
          <h4>1. Have your QR code ready.</h4>
          <br />
          <h4>
            2. Hold your hand on the door sensor for 2-3 seconds until it turns
            on.
          </h4>
          <br />
          <h4>3. Hold the QR code from your phone 1 inch from the sensor.</h4>
          <br />
          <h4>4. Upon beep turn the handle to open the door.</h4>
          <br />
          <h4>
            TO SAVE YOUR KEY DO NOT USE A SCREENSHOT as the code changes
            continuously. Use the "Share" button to save the link to your home
            screen and share the key with your fellow guest via text. 
          </h4>
          <br />
          <label class="checkbox-container">
            <input
              type="checkbox"
              onChange={(event) => {
                toggleChecked(event.target.checked);
              }}
              checked={isChecked}
            />
            <span class="checkmark"> I acknowledge</span>
          </label>
          <br />
          <button
            disabled={!isChecked}
            className={isChecked ? "accept-btn" : "accept-btn-disabled"}
            onClick={() => {
              closeModal();
            }}
          >
            CONTINUE
          </button>{" "}
           
        </div>
      </Popup>
      {loading && <Spinner />}
      <header className="App-header">
        {showOverlay && <ExpireState />}
        {page}
      </header>

      {showA2hs && (
        <button className="add-screen-wrap" onClick={onClick}>
          <div className="screen-thumbnail">
            <div className="thumbnail-img">
              <img src={ScreenIcon} alt="" />
            </div>
            <span className="add-text">Add to Home Screen</span>
          </div>
        </button>
      )}
    </div>
  );
}

export default App;
